@use '../variables' as *;

.clear-fix {
  content: '';
  clear: both;
  display: table;
}

.left {
  float: left;
  text-align: left;
}

.right {
  float: right;
  text-align: right;
}

.center {
  text-align: center;
}

.text {
  &-left {
    text-align: left;
  }

  &-center {
    text-align: center;
  }

  &-right {
    text-align: right;
  }

  &-uppercase {
    text-transform: uppercase;
  }

  &-lowercase {
    text-transform: lowercase;
  }

  &-italic {
    font-style: italic;
  }

  &-primary {
    color: $primary
  }

  &-secondary {
    color: $secondary;
  }

  &-gray {
    color: $gray-250;
  }

  &-white {
    color: $white;
  }

  &-red {
    color: $error;
  }

  &-gray-200 {
    color: $gray-200;
  }

  &-gray-300 {
    color: $gray-300;
  }
}

.hide {
  display: none;
}

.nowrap {
  white-space: nowrap;
}

.wide {
  width: 100%;
}

.shadow {
  box-shadow: $box-shadow;
}

.dropdown {
  position: absolute;
  display: flex;
  flex-direction: column;
  background: $white;
  box-shadow: $box-shadow;
  border-radius: $spacing;
  z-index: 5;
  margin: $spacing * 2 0 0 $spacing * -4;

  a {
    padding: $spacing * 3 $spacing * 5;
    color: initial !important;
    font-weight: initial !important;

    &:hover {
      background: $gray-150;
    }
  }
}

.logo {
  img {
    width: 180px;
  }
}

.bg {
  &-gray-100 {
    background-color: $gray-100;
  }
}

.video-player {
  aspect-ratio: 16/9;
  width: 100%;
}

.pack-large {
  position: relative;

  img {
    width: 244px;
    padding: 0 $spacing * 5 $spacing * 11 $spacing * 5;
  }

  .size {
    position: absolute;
    font-weight: $bold;
    left: 43%;
    bottom: 47%;
  }

  .height, .width, .depth {
    position: absolute;
    text-transform: uppercase;
    font-size: 1.4rem;
    font-weight: $thin;
    border-top: 1px solid $black;
    bottom: 0;
    padding: $spacing 0;
    text-align: center;
    height: 29px;
    white-space: nowrap;

    &:before, &:after {
      float: left;
      content: ' ';
      width: 5px;
      height: 5px;
      margin-top: -7px;
      margin-bottom: -100px;
      transform: rotate(45deg);
      border: 1px solid $black;
      border-right: none;
      border-top: none;
      box-sizing: border-box;
    }

    &:after {
      float: right;
      transform: rotate(225deg);
    }
  }

  .height {
    border-top: none;
    border-bottom: 1px solid $black;
    transform: rotate(-90deg);
    width: 25%;
    bottom: 45%;
    left: -25px;

    &:before, &:after {
      margin: 22px 0 -50px 0;
    }

    &:after {
      margin-left: -100px;
    }
  }

  .width {
    width: 50%;
    left: 18%;
  }

  .depth {
    right: 5%;
    bottom: 10%;
    width: 20%;
    transform: rotate(-18.33deg);
  }

  &.xs {
    .size {
      left: 41%;
      bottom: 49%;
    }

    .height {
      width: 11%;
      left: -5px;
    }
  }

  &.s {
    .height {
      width: 13%;
      left: -10px;
    }
  }

  &.l {
    .size {
      bottom: 45%;
    }

    .height {
      width: 30%;
      bottom: 43%;
    }

    .depth {
      width: 18%;
    }
  }

  &.xl {
    .size {
      bottom: 49%;
    }

    .height {
      width: 44%;
      bottom: 47%;
      left: -50px;
    }

    .depth {
      bottom: 6%;
    }
  }
}

.spinner {
  font-size: 3rem;
  color: $primary;
  box-shadow: inset 0 0 0 4px rgba($primary, 0.3);
  border-radius: 50%;
  animation: spinner 2s forwards infinite linear;
}

@keyframes spinner {
  0% {
    transform: rotate(0);
  }
  50% {
    box-shadow: inset 0 0 0 4px rgba($primary, 0.1);
  }
  100% {
    transform: rotate(360deg);
  }
}

ul.delivery {
  counter-reset: section;
  position: relative;
  margin: 0 auto;
  max-width: 450px;
  padding: $spacing * 6 $spacing * 4;
  box-shadow: 0 0 $spacing * 4 rgba($black, 0.1);

  &:before {
    content: ' ';
    position: absolute;
    top: 2rem;
    left: 3rem;
    height: calc(100% - 4rem);
    width: 1px;
    border-left: 3px dotted $gray-200;
  }

  li {
    padding: $spacing * 4 0;
    font-size: 1.6rem;
    line-height: 1.1em;
    color: $gray-500;

    small {

    }

    &:first-child {
      padding-top: 0;
    }

    &:last-child {
      padding-bottom: 0;
    }

    &:before {
      content: ' ';
      background-color: $gray-200;
      border: 4px solid $white;
      width: 2.4rem;
      height: 2.4rem;
      line-height: 2.2rem;
      text-align: center;
      color: $white;
      border-radius: 50%;
      margin: -6px $spacing * 2 0 0;
      float: left;
      font-size: 1.4rem;
      position: relative;
    }

    &.status {
      &-1:before {
        content: ' ';
      }

      &-2:before {
        background-color: $primary;
      }

      &-3:before {
        content: '✓';
        background-color: $primary;
      }
    }
  }
}

.map {
  background-color: $gray-200;
  width: 100%;
  aspect-ratio: 16/9;
  display: flex;
  align-items: center;
  justify-content: center;

  .infoBox {
    overflow: visible;
    height: 130px;

    .inner {
      background-color: $white;
      display: inline-block;
      padding: $spacing * 2 $spacing * 3;
      width: 190px;
      border-radius: $spacing;
      box-shadow: $box-shadow;
      font-size: 1.2rem;
      font-family: Arial, sans-serif;
      position: relative;

      .close {
        float: right;
        color: $black;
        font-size: 1.4rem;
        margin: -2px -2px 0 $spacing * 2;
      }

      &:after {
        content: ' ';
        display: block;
        background-color: $white;
        width: $spacing * 3;
        height: $spacing * 3;
        transform: rotate(45deg);
        left: calc(50% - $spacing * 2.8);
        position: absolute;
      }
    }
  }
}

.border-box {
  border: 2px solid $gray-150;
  border-radius: $spacing;
  padding: $spacing * 7 $spacing * 8;

  img {
    &.itella {
      margin: 0 0 -11px 0;
      height: 2.4rem;
    }

    &.omniva {
      margin: 0 0 -5px 0;
      height: 3.1rem;
    }

    &.venipak {
      margin: 0 0 -3px 0;
    }
  }

  &.border-thin {
    border-width: 1px;
  }

  &.border-primary {
    border-color: $primary;
  }
}

.sticky {
  &-bottom {
    position: sticky;
    bottom: -1px;
    background-color: $white;
    border-top: 1px solid $gray-200;
  }
}

.tabs {
  border-bottom: 1px solid $gray-200;

  a, button {
    padding: 1rem 2.5rem;
    display: inline-block;
    color: $gray-500;
    border-radius: 0;
    border: none;
    background-color: transparent;
    font-size: 1.4rem;
    font-weight: 400;
    transition: none;

    &:hover {
      border-bottom: 2px solid $gray-300;
      margin-bottom: -1px;
      text-decoration: none;
    }

    &.active {
      color: $primary;
      font-weight: 700;
      border-bottom: 2px solid $primary;
      margin-bottom: -1px;
    }

    &.disabled {
      pointer-events: none;
      color: $gray-300;
    }
  }

  @include breakpoint(sm) {
    white-space: nowrap;
    overflow-x: auto;
    overflow-y: hidden;
    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      width: 0 !important;
      height: 0 !important;
    }

    a, button {
      padding: 1rem 1.5rem;
    }
  }
}

@include breakpoint(sm) {
  .map {
    aspect-ratio: auto;
    height: 70vh;
  }

  .pack-large {
    .height {
      top: -5rem;
      left: -15%;
    }

    .width {
      bottom: -3.3rem;
    }

    .depth {
      bottom: -2.7rem;
      right: 0;

      &:after {
        margin-top: -2.6rem;
      }
    }

    .size {
      border: none;
      bottom: 2.8rem;
    }

    &.xs {
      margin-top: -1rem;

      .size {
        bottom: 3rem;
      }

      .height {
        left: -12%;
      }

      .width {
        bottom: -1.5rem;
      }

      .depth {
        bottom: -0.9rem;
      }
    }

    &.s {
      margin-top: -1rem;

      .height {
        left: -12%;
      }

      .width {
        bottom: -2rem;
      }

      .depth {
        bottom: -1.4rem;
      }
    }

    &.m {
      + .col {
        margin-top: 1rem;
      }
    }

    &.l {
      margin-top: -1rem;

      .size {
        bottom: 2.2rem;
      }

      .width {
        bottom: -4rem;
      }

      .depth {
        bottom: -3.4rem;
        right: 0.5rem;
      }
    }

    &.xl {
      .size {
        bottom: 2.8rem;
      }

      .height {
        left: -25%;
      }

      .width {
        bottom: -5.5rem;
      }

      .depth {
        bottom: -5rem;
      }

      + .col {
        margin-top: 3rem;
      }
    }
  }
}