@use '../../scss/variables' as *;

.pack {
  background-color: $gray-100;
  border: 1px solid $gray-200;
  border-radius: $spacing * 2;
  margin-top: $spacing * 4;
  padding: $spacing * 3 $spacing * 6;

  @include breakpoint(sm) {
    padding: $spacing * 2 $spacing * 4;
  }

  .main {
    display: flex;
    align-items: center;

    div {
      flex-grow: 1;

      &.right {
        margin-left: auto;
        flex-grow: 0;

        .status {
          font-size: 1.5rem;
          font-weight: $bold;
          text-align: left;
          min-width: 14rem;

          small {
            font-size: 1.2rem;
            font-weight: $thin;
            color: $gray-300;
            display: block;
          }

          &.open {
            color: $error;
          }

          &.shipped {
            color: $primary-light;
          }

          &.delivered {
            color: $success-dark;
          }
        }

        svg {
          padding: 0.5rem;
          cursor: pointer;
        }
      }
    }
    @include breakpoint(sm) {
      small {
        max-width: 40vw;
        overflow: hidden;
        text-overflow: ellipsis;
        display: inline-block;
      }
      div.right {
        .status {
          min-width: 80px;
          small {
            max-width: 80px;
            max-height: 2rem;
          }
        }
      }
    }
  }

  .collapse {
    max-height: 0;
    overflow: hidden;
    transition: max-height ease-in-out 0.3s;
    &.show {
      max-height: 500px;
      @include breakpoint(sm) {
        max-height: 800px;
      }
    }
  }

  .details, .tracking {
    background-color: $white;
    padding: $spacing * 4;
    margin: $spacing * 4 0;
    border-radius: $spacing * 2;
    box-shadow: 0 0 4px rgba($black, 0.05);
    display: flex;
    flex-direction: column;
    justify-content: left;
    align-items: baseline;
    gap: $spacing;
    box-sizing: border-box;
    img {
      height: 29px;
      display: block;
    }
    ul.delivery {
      max-width: 100%;
      width: 100%;
      margin: 0;
      box-sizing: border-box;
    }
  }
}