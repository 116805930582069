@use '../variables' as *;

#page {
  background: $white;
  max-width: $page-width;
  text-align: left;
  margin: 0 auto;
  padding: 0 1rem;
}

@include breakpoint(sm) {
  #page {
    width: 100%;
  }
}