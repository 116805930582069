// Colors
$white: #ffffff;
$black: #000000;
$link: #3072E5;
$text: #333333;

$primary-light: #FBA020;
$primary: #fb6521;
$primary-dark: #f04f04;
$secondary: #33c02d;
$secondary-dark: #2ba226;

$success-lightest: #e2eeda;
$success-light: #dae7c9;
$success: #4a7443;
$success-dark: #028E59;

$notice-lightest: #FCF8E3;
$notice-light: #FAEBCC;
$notice: #8A6D3B;


$error-lightest: #fce5e5;
$error-light: #f8d5d5;
$error: #ea0101;


$gray-100: #fbfbfb;
$gray-150: #e9ecef;
$gray-200: #eaecef;
$gray-250: #cfd4da;
$gray-300: #a29f9f;
$gray-350: #6E757D;
$gray-400: #636363;
$gray-500: #3c3c3b;
$gray-700: #222222;

$blue-100: #F3F5F5;

// Grid
$page-width: 1100px;
$screen-xs-min: 320px;
$screen-sm-min: 768px;
$screen-md-min: 992px;
$screen-lg-min: 1200px;
$spacing: 4px;
$gap: $spacing * 3;

// Misc
$box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
$thin: 400;
$normal: 600;
$bold: 700;


// some mixins
@mixin breakpoint($point) {
  @if $point == 'xs' {
    @media (max-width: $screen-xs-min) {
      @content;
    }
  } @else if $point == 'sm' {
    @media (max-width: $screen-sm-min) {
      @content;
    }
  } @else if $point == 'md' {
    @media (max-width: $screen-md-min) {
      @content;
    }
  } @else if $point == 'lg' {
    @media (max-width: $screen-lg-min) {
      @content;
    }
  }
}
