@use '../variables' as *;

/*  Padding && margin classes */
$directions: (
        t: 'top',
        r: 'right',
        b: 'bottom',
        l: 'left',
);

.p {
  @for $i from 0 to 10 {
    $space: $i * $spacing;
    &-#{$i} {
      padding: #{$space} !important;
    }
    &--#{$i} {
      padding: #{$space*-1} !important;
    }
    &h-#{$i} {
      padding-left: #{$space} !important;
      padding-right: #{$space} !important;
    }
    &v-#{$i} {
      padding-top: #{$space} !important;
      padding-bottom: #{$space} !important;
    }
    @each $dir, $direction in $directions {
      &#{$dir}-#{$i} {
        padding-#{$direction}: #{$space} !important;
      }
    }
  }
  &-0 {
    padding: 0;
  }
}

.m {
  @for $i from 0 to 24 {
    $space: $i * $spacing;
    &-#{$i} {
      margin: #{$space} !important;
    }
    &--#{$i} {
      margin: #{$space*-1} !important;
    }
    &h-#{$i} {
      margin-left: #{$space} !important;
      margin-right: #{$space} !important;
    }
    &v-#{$i} {
      margin-top: #{$space} !important;
      margin-bottom: #{$space} !important;
    }
    &h--#{$i} {
      margin-left: #{$space*-1} !important;
      margin-right: #{$space*-1} !important;
    }
    &v--#{$i} {
      margin-top: #{$space*-1} !important;
      margin-bottom: #{$space*-1} !important;
    }
    @each $dir, $direction in $directions {
      &#{$dir}-#{$i} {
        margin-#{$direction}: #{$space} !important;
      }
      &#{$dir}--#{$i} {
        margin-#{$direction}: #{$space*-1} !important;
      }
    }
  }
  @each $dir, $direction in $directions {
    &#{$dir}-auto {
      margin-#{$direction}: auto !important;
    }
  }
  &-0 {
    margin: 0;
  }
  &-auto {
    margin: auto;
  }
  &-large-icon {
    margin-bottom: -2px;
  }
}

// flex boxes
.flex,
[class^='flex-'],
[class*=' flex-'] {
  display: flex;
  gap: $spacing * 4;
  .border-box {
    height: 100%;
  }
}
.flex {
  &-row {
    flex-direction: row;
    &.items-3 {
      flex-wrap: wrap;
      > .col {
        flex-basis: 32%;
      }
    }
  }
  &-column {
    flex-direction: column;
  }
  &-end {
    justify-content: end;
  }
  &-center {
    align-items: center;
  }
  &-nowrap {
    flex-wrap: nowrap;
  }
  &-wrap {
    flex-wrap: wrap;
  }
  &-stretch {
    align-items: stretch;
  }
}
.col {
  width: 100px;
  flex-grow: 1;
  flex-shrink: 1;
  &-1-2 {
    width: 50%;
  }
  &-1-3 {
    width: 33%;
  }
  &-2-3 {
    width: 66%;
  }
  &-1-4 {
    width: 25%;
  }
  &-3-4 {
    width: 75%;
  }
  &-1-5 {
    width: 20%;
  }
  &-2-5 {
    width: 40%;
  }
  &-3-5 {
    width: 60%;
  }
  &-4-5 {
    width: 80%;
  }
}
.gap {
  &-0 {
    gap: 0;
  }
  &-h0 {
    column-gap: 0;
  }
  &-v0 {
    row-gap: 0;
  }
  @for $i from 0 to 8 {
    &-#{$i} {
      gap: #{$i * $spacing} !important;
    }
  }
}

.max-w {
  &-1-3 {
    max-width: 33.3%;
  }
  &-1-2 {
    max-width: 50%;
  }
  &-2-3 {
    max-width: 66.6%;
  }
}

@include breakpoint(sm) {
  .flex,
  [class^='flex-'],
  [class*=' flex-'] {
    gap: $spacing * 2;
  }
  .flex {
    &-row:not(.mobile-row) {
      flex-direction: column;
      .col {
        width: 100%;
      }
    }
    &-row.mobile-row {
      .col {
        &-1-5 {
          width: 35%;
          /* special case */
          button {
            padding: $spacing * 3 $spacing * 2;
          }
        }
      }
    }
  }
}
@include breakpoint(xs) {
  .flex {
    &-row.mobile-row {
      .col {
        &-1-5 {
          /* special case */
          button {
            letter-spacing: -0.1rem;
          }
        }
      }
    }
  }
}