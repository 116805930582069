@use '../variables' as *;

.message {
  background-color: $gray-150;
  border: 1px solid $gray-200;
  border-radius: $spacing;
  padding: $spacing * 2 $spacing * 3;

  &.success {
    background-color: #E2EEDA;
    border-color: #DAE7C9;
    color: #4A7443;
    font-weight: $bold;
  }
}