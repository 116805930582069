@use '../../scss/variables' as *;

.navbar {
  background: $blue-100;
  color: #3C3C3B;
  padding: 0 $spacing * 4;

  .inner {
    max-width: $page-width;
    text-align: left;
    margin: 0 auto;
    display: flex;
    align-items: center;
    padding: $spacing * 2 0;
    font-weight: 400;

    img {
      float: left;
    }

    a, button {
      color: #3C3C3B;
      transition: all ease-in-out 0.2s;
      text-decoration: none;
      font-size: 1.4rem;
      white-space: nowrap;
      font-weight: 400;

      &:hover {
        color: $primary;
      }
    }

    .left {
      display: flex;
      align-items: center;
      gap: $spacing * 4;
      flex-grow: 1;

    }

    .right {
      display: flex;
      gap: $spacing * 4;
      a {
        line-height: 2em;
        vertical-align: middle;
        svg {
          font-size: 1.2em;
          margin: 0 $spacing * 1.5 -1px 0;
        }
      }
    }
  }
  .avatar {
    width: 30px;
    height: 30px;
    object-fit: cover;
    border-radius: $spacing;
    margin-right: $spacing * 2;
  }
  .messages {
    background-color: $primary;
    color: $white;
    border-radius: $spacing;
    font-size: 0.9em;
    padding: 0 $spacing;
    margin-left: $spacing;
    &:empty {
      display: none;
    }
  }
}

@include breakpoint(sm) {
  .navbar {
    padding: 1rem;
    .inner {
      padding: 0;
      .left {
        gap: 0;
        img {
          max-width: 30vw;
        }
      }
      button {
        padding: 0 1rem;
      }
    }
  }
}