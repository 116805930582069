@use 'variables' as *;
@use 'partials/html';
@use 'partials/grid';
@use 'partials/buttons';
@use 'partials/forms';
@use 'partials/main';
@use 'partials/general';
@use 'partials/modals';
@use 'partials/messages';

@include breakpoint(sm) {
  .hide-on-mobile {
    display: none;
  }
}

@include breakpoint(xs) {
  .hide-on-xs {
    display: none;
  }
}