@use '../../scss/variables' as *;

.footer {
  background: $white;
  color: $gray-400;
  padding: $spacing 0 $spacing * 16 0;
  margin-top: $spacing * 8;
  border-top: 1px solid #e6e6e6;

  .inner {
    margin: 0 auto;
    padding: 0 2rem;
    text-align: left;
    max-width: $screen-lg-min;
    font-size: 1.5rem;

    h3 {
      color: #212529;
      font-weight: $thin;
    }
    hr {
      background-color: rgba($gray-200, 0.5);
    }

    a {
      color: $gray-400;
    }

    ul {
      padding: $spacing 0;
      li {
        padding: $spacing 0;
        svg {
          margin-right: $spacing * 2;
        }
      }
    }

    .group-items {
      font-size: 1.3rem;
      a {
        font-weight: $thin;
        &:hover {
        text-decoration:none;
          color: $primary;
        }
      }
    }
  }
}