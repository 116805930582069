@use '../variables' as *;

input, textarea, select {
  background-color: $white;
  border: 1px solid $gray-250;
  border-radius: $spacing;
  padding: $spacing * 3.5;
  font-size: 1.5rem;
  font-weight: $normal;
  width: 100%;
}

label {
  position: relative;

  input, textarea, select {
    + span {
      position: absolute;
      left: 1px;
      top: -1px;
      padding: $spacing * 3.5;
      font-weight: $thin;
      color: $gray-350;
      transition: all ease-in-out 0.1s;
    }
    &:not([type="checkbox"]) + span {
      pointer-events: none;
    }

    &:focus, &:not(:placeholder-shown) {
      outline: none;

      + span {
        top: $spacing * -2.2;
        left: $spacing * 2.5;
        padding: 0 $spacing * 2;
        color: $gray-700;
        font-size: 1.2rem;
        text-shadow:
                -1px -1px 0 $white,
                1px -1px 0 $white,
                -1px 1px 0 $white,
                1px 1px 0 $white,
                -2px -2px 0 $white,
                2px -2px 0 $white,
                -2px 2px 0 $white,
                2px 2px 0 $white,
                -3px -3px 1px $white,
                3px -3px 1px $white,
                -3px 3px 1px $white,
                3px 3px 1px $white,

                -3px 0 0 $white,
                3px 0 0 $white,
                -4px 0 0 $white,
                4px 0 0 $white,
                -5px 0 0 $white,
                5px 0 0 $white,
                -6px 0 0 $white,
                6px 0 0 $white;

      }
    }

    &:focus {
      border-color: $primary;
    }

    &[type="checkbox"] {
      opacity: 0;
      pointer-events: none;

      + span {
        display: block;
        background: transparent;
        font-size: 1.5rem;
        color: $gray-500;
        font-weight: $normal;
        padding: $spacing * 2 0 $spacing * 2 $spacing * 7;
        margin-bottom: $spacing * -3;
        clear: both;
        position: relative;

        &:before {
          position: absolute;
          left: $spacing * -2.5;
          top: $spacing * 1.5;
          content: ' ';
          display: inline-block;
          background-color: $white;
          border: 1px solid $gray-100;
          width: $spacing * 6;
          height: $spacing * 6;
          border-radius: $spacing;
          transition: all ease-in-out 0.1s;
          cursor: pointer;
          box-shadow: 0 0 $spacing * 2 $primary;
        }

        + .error {
          margin-left: $spacing * 8;
        }
      }

      &:checked {
        + span {
          &:before {
            background-color: $primary-dark;
            border-color: $primary-dark;
          }

          &:after {
            content: url("data:image/svg+xml,%3Csvg aria-hidden='true' focusable='false' data-prefix='fas' data-icon='check' class='svg-inline--fa fa-check mr-1' role='img' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 448 512'%3E%3Cpath fill='white' d='M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z'%3E%3C/path%3E%3C/svg%3E");
            text-shadow: none;
            position: absolute;
            left: $spacing * -1.2;
            top: $spacing * 2;
            font-size: 2rem;
            color: $white;
            width: 1.66rem;
            height: 1.66rem;
            cursor: pointer;
          }
        }
      }
    }

    &:active, &:focus-within {
      + span {
        &:before {
          box-shadow: 0 0 $spacing * 2 $primary;
          border-color: $primary-dark;
        }
      }
    }
  }

  .error {
    font-size: 1.4rem;
    font-weight: $thin;
    color: $error;
    padding: $spacing;

    &:before {
      content: '* ';
    }
  }

  .pre-icon {
    position: absolute;
    left: $spacing * 3;
    padding: $spacing * 4 $spacing * 2 $spacing * 2.2 0;
    z-index: 1;
    color: $gray-350;
    font-weight: $thin;

    + input {
      padding-left: $spacing * 9;

      + span {
        margin-left: $spacing * 6;
      }
    }

    &.wide {
      border-right: 1px solid $gray-200;
      width: auto;
      color: $gray-500;
      padding: $spacing * 3.2 $spacing * 2 $spacing * 2.9 0;
      top: 1px;
      img {
        margin: $spacing $spacing $spacing * -0.5 0;
      }
      + input {
        padding-left: $spacing * 23;
        + span {
          margin-left: $spacing * 20;
        }
      }
    }
  }
}

@include breakpoint(md) {
  form.col-1-2 {
    width: 100%;
  }
}
@include breakpoint(sm) {

}