@use '../variables' as *;
html {
  font-size: 62.5%;
}
body {
  margin: 0;
  padding: 0;
  font-family: 'Open Sans', sans-serif;
  font-size: 1.6rem;
  font-weight: $normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: $white;
  color: $text;
  text-align: center;
}
div, span, header, body, footer, p, input, textarea, select {
  box-sizing: border-box;
}
a {
  color: $link;
  cursor: pointer;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
  &:disabled, .disabled {
    cursor: none;
    pointer-events: none;
  }
}
small, .small {
  font-size: 0.875em;
}
.smaller {
  font-size: 0.95em;
}
.large {
  font-size: 1.2em;
}
.largest {
  font-size: 1.6em;
}
.thin {
  font-weight: $thin;
}
strong, .strong {
  font-weight: $bold;
}
.light {
  color: $gray-350;
}

button {
  &.link {
    @extend a;
    border: none;
    background: none;
    padding-left: 0;
    padding-right: 0;
  }
}
h1, h2, h3 {
  color: $black;
  margin: $spacing * 6 0;
}
h3 {
  margin: $spacing * 11 0 $spacing * 4;
}
hr, .hr {
  background-color: $gray-200;
  border: none;
  height: 1px;
  &.light {
    background-color: $gray-150;
  }
  &.fat {
    height: 2px;
  }
}
ul, ol {
  padding: 0;
  margin: 0;
  li {
    padding: 0;
    margin: 0;
    list-style: none;
    font-size: 1.4rem;
  }
  &.columns {
    &-2 {
      columns: 2;
      width: 100%;
    }
  }
}
ol {
  counter-reset: section;
  position: relative;
  &:before {
    content: ' ';
    position: absolute;
    top: 2rem;
    left: calc(1rem + 5px);
    height: calc(100% - 4rem);
    width: 2px;
    background-color: $gray-200;
  }
  li {
    padding: $spacing * 4 0;
    &:first-child {
      padding-top: 0;
    }
    &:last-child {
      padding-bottom: 0;
    }
    &:before {
      counter-increment: section;
      content: counter(section);
      background-color: $gray-200;
      border: 4px solid $gray-100;
      width: 2.4rem;
      height: 2.4rem;
      line-height: 2.2rem;
      text-align: center;
      color: $white;
      border-radius: 50%;
      margin: -6px $spacing * 2 1.5rem 0;
      float: left;
      font-size: 1.4rem;
      position: relative;
    }
  }
}
img {
  &.gray {
    filter: grayscale(100%);
  }
}
@include breakpoint(sm) {
  html, body {
    overflow-x: hidden;
    width: 100vw;
  }
  h3 {
    font-size: 1.6rem;
  }
}