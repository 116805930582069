@use '../variables' as *;

.modal {
  display: none;
  background-color: transparent;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
  align-items: center;
  justify-content: center;
  transition: background-color ease-in-out 0.5s;

  &.show {
    display: flex;
    background-color: #3C3C3BA6;
  }

  > div {
    position: relative;
    z-index: 1001;
    max-width: calc(100vw - $spacing * 4);
    max-height: 90vh;
    width: 600px;
    overflow: auto;
    background: $white;
    border-radius: $spacing;
    padding: $spacing * 4;

    h3:first-child {
      margin-top: $spacing * 2;
    }
  }
  &.large {
    > div {
      width: 900px;
    }
  }
}
@include breakpoint(sm) {
  .modal {
    > div {
      padding: $spacing * 2;
      max-width: 100%;
      max-height: 100%;
      width: 100vw;
      height: 100vh;
    }
  }
}