@use '../../scss/variables' as *;

.autocomplete {
  background-color: $white;
  position: absolute;
  width: 100%;
  z-index: 100;
  margin-top: -2px;
  border: 1px solid $gray-200;
  box-shadow: $box-shadow;
  ul {
    max-height: 30vh;
    overflow: auto;
    li {
      padding: $spacing * 2 $spacing * 5;
      border-bottom: 1px solid $gray-150;
      font-weight: $thin;
      cursor: pointer;
      &:last-child {
        border-bottom: none;
      }
      &.active {
        background-color: $gray-150;
        border-bottom-color: $gray-200;
        border-top: 1px solid $gray-200;
        margin-top: -1px;
      }
      &.group-title {
        pointer-events: none;
        font-weight: $bold;
        border-bottom-color: $gray-200;
        background-color: $gray-100;
        &:not(:first-child) {
          margin-top: -1px;
          border-top: 1px solid $gray-200;
        }
      }
      &[data-group] {
        padding: $spacing * 1.75 $spacing * 4;
        margin-left: $spacing * 2;
        border-left: 1px solid $gray-200;
      }
      small {
        color: $gray-400;
      }
    }
  }
}