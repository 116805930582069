@use '../../scss/variables' as *;

.header {
  background: #FB65210A;
  text-align: center;
  .container {
    max-width: $page-width;
    text-align: left;
    margin: 0 auto;
    padding: 0 1rem;
    height: 221px;
    position: relative;
    display: flex;
    align-items: center;

    .slogan {
      padding-top: 3rem;
      font-size: 4rem;
      line-height: 1.2em;
      max-width: 50%;
      font-weight: 700;
      white-space: nowrap;
    }

    .logos {
      margin-top: 2rem;
      display: flex;
      align-items: center;
      gap: $gap;

      .smartpost {
        margin-top: $spacing * 2.6;
      }
      .unisend {
        margin-top: $spacing - 1;
        height: 1.2rem;
      }
      .venipak {
        margin-top: $spacing;
        height: 1.2rem;
      }
    }

    .boxes {
      position: absolute;
      top: 1.5rem;
      right: 0;
      width: 282px;
      height: 190px;
      display: flex;
      align-items: center;
      justify-content: right;
      img {
        max-width: 100%;
        max-height: 100%;
        float: right;
      }
    }
  }
}

@include breakpoint(sm) {
  .header {
    padding: 0;
    .container {
      height: unset;
      padding: 2rem;
      .slogan {
        font-size: 2rem;
        padding: 0;
        position: relative;
        z-index: 1;
      }
      .boxes {
        max-width: 30vw;
        top: 1rem;
        right: 0.5rem;
        max-height: calc(100% - 2rem);
        background-position: right;
      }
      .logos {
        margin-top: 0.2rem;
        position: relative;
        z-index: 1;
        flex-wrap: wrap;
      }
    }
  }
}