@use '../variables' as *;

.btn,
button,
[class^='btn-'],
[class*=' btn-'] {
  display: inline-block;
  background: $gray-100;
  border: 1px solid $gray-200;
  border-radius: $spacing;
  padding: $spacing * 3 $spacing * 5;
  color: $gray-500;
  text-decoration: none;
  box-sizing: border-box;
  transition: all ease-in-out 0.2s;
  cursor: pointer;
  white-space: nowrap;
  text-align: center;
  font-size: 1.6rem;
  font-family: 'Open Sans', sans-serif;
  font-weight: $normal;

  &:hover {
    border-color: $gray-300;
    text-decoration: none;
  }

  &:disabled,  &.disabled {
    cursor: default;
    pointer-events: none;
    background-color: $gray-150;
    border-color: $gray-200;
    color: $gray-300;
  }

  &.outline {
    background-color: $white;
  }
}

.btn {
  &-primary {
    background: $primary;
    color: $white;
    border-color: $primary;

    &:hover, .active {
      background: $primary-dark;
      border-color: $primary-dark;
    }

    &.outline {
      color: $primary;
      &:hover {
        color: $white;
      }
    }
  }


  &-secondary {
    background: $secondary;
    color: $white;
    border-color: $secondary;

    &:hover {
      background: $secondary-dark;
      border-color: $secondary-dark;
    }

    &.outline {
      color: $secondary;
      &:hover {
        color: $white;
      }
    }
  }

  &-light {
    background: $white;
    color: $gray-500;
    border-color: $gray-250;

    &:hover {
      color: $gray-700;
      border-color: $gray-300;
    }
  }

  &-dark {
    background: $gray-500;
    color: $white;
    border-color: $gray-500;

    &:hover {
      background: $gray-700;
      border-color: $gray-700;
    }

    &.outline {
      color: $gray-500;
      &:hover {
        color: $white;
      }
    }
  }

  // special buttons
  &-menu {
    width: 100%;
    font-weight: $bold;
    font-size: 2rem;
    padding: $spacing * 7 $spacing * 6;
    text-align: left;
    .right {
      font-size: 1.4rem;
      margin-top: 0.4rem;
      font-weight: $normal;
    }
    &.active {
      background: rgba($primary, 0.03);
      border-color: $primary;
    }
  }

  &-pack {
    height: 100%;
    white-space: initial;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: end;
    padding: $spacing * 3;
    position: relative;
    .chosen {
      display: none;
    }
    > img {
      width: 113px;
      padding: $spacing * 2 0;
    }
    .details {
      padding: $spacing * 3 0;
      .title {
        font-size: 3.4rem;
        font-weight: $bold;
        margin-right: $spacing * 2;
      }

      .price {
        font-size: 1.4rem;
        color: $primary;
        font-weight: $normal;
      }
    }
    .size {
      font-weight: $thin;
      font-size: 1.2rem;
      text-transform: uppercase;
      border-top: 1px solid $gray-200;
      white-space: nowrap;
      padding-top: $spacing * 3;
      width: 100%;
      strong {
        font-weight: $normal;
        text-transform: initial;
      }
    }
    &.active {
      background: rgba($primary, 0.03);
      border-color: $primary;
      .chosen {
        display: block;
        position: absolute;
        top: 0;
        transform: translateY(-50%);
        font-size: 1.3rem;
        background-color: $secondary;
        color: $white;
        border-radius: $spacing;
        padding: $spacing * 0.25 $spacing * 1.5;
        text-transform: uppercase;
      }
      .details {
        .title {
          color: $primary;
        }
      }
    }
  }

  &-provider {
    width: 100%;
    text-align: left;
    background-color: $gray-100;
    .text-primary {
      font-size: 2.5rem;
    }
    .titles {
      font-size: 1.4rem;
      color: $gray-250;
      text-transform: uppercase;
      gap: 0;
      .col {
        width: 25%;
        flex-shrink: 0;
        flex-grow: 0;
      }
    }
    .sizes {
      font-weight: $bold;
      text-transform: uppercase;
      gap: 0;
      .col {
        width: 25%;
        flex-shrink: 0;
        flex-grow: 0;
      }
    }
    &.active {
      background: rgba($primary, 0.03);
      border-color: $primary;
      img {
        filter: none;
      }
    }

    img {
      filter: grayscale(100%);
    }
    img.itella {
      margin: 0 5px -9px 0;
      height: 2rem;
    }
    img.omniva {
      margin: 0 5px -3px 0;
      height: 2.4rem;
    }
    img.unisend {
      margin: 0 5px 0 0;
      height: 1.4rem;
    }
    img.venipak {
      margin: 0 5px -2px 0;
      height: 1.4rem;
    }
  }
}

@include breakpoint(sm) {
  .btn {
    // special buttons
    &-menu {
      font-size: 1.6rem;
      padding: 1.6rem;
      .right {
        margin-top: 0.1rem;
      }
    }
    &-pack {
      > div {
        max-width: 130px;
        align-self: baseline;
        text-align: left;
        margin-left: 1.5rem;
        &.chosen {
          display: none !important;
        }
        &.details {
          padding: 0.5rem 0;
        }
      }
      img {
        position: absolute;
        right: 2rem;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }
  div.btn-pack {
    .pack-large {
      position: absolute;
      right: 0;
      width: 40vw;
      img {
        width: 100%;
        right: -2rem;
        top: -2.5rem;
      }
    }
    .flex-row {
      max-width: 100%;
      .max-w-1-3 {
        max-width: calc(100% - 3rem);
      }
      &.mobile-row {
        position: relative;
        img {
          position: initial;
          transform: none;
        }
      }
    }
    .btn-provider:not(.active) {
      background: $white;
    }
  }
}
@include breakpoint(xs) {
  div.btn-pack {
    .flex-row {
      .max-w-1-3 {
        max-width: calc(100% + 1rem);
        width: 100vw;
        margin-left: -2rem;
        margin-right: -1rem;
        .titles {
          font-size: 0.8em;
        }
      }
    }
  }
}